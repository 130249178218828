import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  scrWidth: any;
  pdfSrcSum2019 = '../../assets/newsletters/newsletterSummer2019.pdf';
  pdfSrcSum2020 = '../../assets/newsletters/newsletterSummer2020.pdf';
  pdfSrcAnnRep2020 = '../../assets/newsletters/newsletterAnnualReport2020.pdf';
  pdfSrcAnnRep2021 = '../../assets/newsletters/newsletterAnnualReport2021.pdf';
  iahpcNewsletterSrc = '../../assets/newsletters/newsletterIAHPC.pdf';
  pdfSrcAnnRep2022 = '../../assets/newsletters/newsletterAnnualReport2022.pdf';
  pdfSrcAnnRep2023 = '../../assets/newsletters/newsletterAnnualReport2023.pdf';
  pdfSrcJulDec2024 = '../../assets/newsletters/newsletterJulyDecemberReport2024.pdf';
  collapse = true;
  newsletter = 'sum2019';

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 767) {
      this.collapse = true;
    }
  }

  constructor() {
    this.getScreenSize();
  }

  menuToggle() {
    this.collapse = !this.collapse;
  };

  changeHistory(newsletter: string) {
    this.newsletter = newsletter;
  }
  ngOnInit() {
  }

}
